.content-area-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	flex-wrap: wrap;
	// margin-bottom: 30px;

	& .area-top-title {
		color: var(--text-color-inverted);
	}

	& .area-top-l {
		display: flex;
		align-items: center;
		column-gap: 6px;
	}
}
.sidebar-open-btn {
	display: inline-flex;
	align-items: center;
	display: none;
	margin-bottom: -1px;
	color: var(--text-color-inverted);

	@media (max-width: 1024px) {
		display: inline-flex;
	}
}
