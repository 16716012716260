#paper {
	padding: 15px;
	margin-bottom: 30px;
	border: 1px solid #fff;
	background-color: var(--paper-bg-color);

	& label {
		color: var(--text-color-inverted) !important;
	}

	input[type='date']::-webkit-calendar-picker-indicator {
		cursor: pointer;
		opacity: 1;
		filter: invert(0.8);
	}

	& .rbc-calendar {
		color: var(--text-color-inverted) !important;
	}

	& .rbc-toolbar {
		& .rbc-active {
			color: #000 !important;
		}
		& button {
			color: var(--text-color-inverted) !important;

			&:hover {
				color: #000 !important;
			}
		}
	}

	& .MuiDataGrid-row {
		color: var(--text-color-inverted) !important;
	}

	& .MuiDataGrid-overlay {
		color: var(--text-color-inverted) !important;
	}

	& .MuiDataGrid-toolbarContainer {
		& .MuiButton-root {
			color: var(--text-color-inverted) !important;
		}
	}
}
.card-header {
	color: #fff !important;
	font-size: 24px;
	background-color: var(--primary-color);
	padding: 15px;
}
