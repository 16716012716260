#dashboard {
	color: var(--text-color-inverted);
	& .container-reports {
		border: 1px solid #ccc;
		background-color: var(--secondary-color);
		border-radius: 15px;
		padding: 15px;
		-webkit-box-shadow: 0px 6px 12px -7px #000000;
		box-shadow: 0px 6px 12px -7px #000000;
		justify-content: center;
		min-height: 350px;

		& .title-module {
			font-weight: bold;
			font-size: 25px;
			margin-bottom: 10px;
			color: var(--text-color-inverted);
		}

		& img {
			width: 150px;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		& #text1,
		#text2,
		#text3,
		#text4,
		#text5 {
			opacity: 0;
		}
	}

	& .titulo-reportes {
		font-size: 35px;
		font-weight: bold;
		color: var(--text-color-inverted);
	}
}

.contenedor-password {
	background-color: var(--secondary-color);
	padding: 20px;
}
