#encuesta {
	margin-top: 0;
	font-size: 18px;

	& .header-free {
		background-color: #e10613;
		padding: 15px;
		margin-bottom: 40px;
		position: sticky;
		width: 100%;
		top: 0%;
		z-index: 9;
		& img {
			width: 150px;
		}
	}

	& .titulo-encuesta {
		font-size: 22px;
		font-weight: bold;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
		color: #747373;
	}

	& .pregunta {
		font-size: 18px;
		font-weight: bold;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
		color: #747373;
		margin-bottom: 10px;
	}

	& .MuiFormControl-root {
		display: flex;

		& .MuiFormGroup-root {
			justify-content: space-between !important;
		}
	}

	& .escala {
		font-size: 14px;
		display: flex;
		justify-content: space-between;
	}
}
