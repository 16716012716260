#reportes {
	& .recharts-yAxis .recharts-text,
	& .recharts-xAxis .recharts-text {
		transform: translate(0, 0px);
		fill: var(--side-text-color) !important;
	}

	& p {
		margin-bottom: 10px;
	}

	#dimenciones {
	}
}
